import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeDtoBlogPost,
    doppeDtoBlogPostGetMedia,
    DoppeExternalBlogPostProvider,
    doppeExternalBlogPostProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueGetVisibleValue
} from '../../client-server-common';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {arrayRemoveNullValues, immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    getDefaultDoppeActionCtaButtonSettings, doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';

export namespace DoppeActionTypeBlogPost {
    export interface ActionSettings extends DoppeActionCtaButtonSettings{
        blogPostLink: string;
        blogPostTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        blogPostButtonText: DoppeHideableValue<string>;
        blogPostImages: WixImageResource[] | null;
        blogPostDate: DoppeHideableValue<Date>;
        showBlogWebsite: boolean;
        blogPostProvider: DoppeExternalBlogPostProvider | null;
        blogPostProviderId: DoppeDtoBlogPost.Id | null;
    }
}

export const doppeActionTypeBlogPost = doppeActionTypeDefineMetadata<DoppeActionTypeBlogPost.ActionSettings>({
    id: 'io.bymo.action/blogPost',
    resolveActionSettings: action => {

        const {
            blogPostLink = '',
            blogPostButtonText = '',
            ctaButton,
            ...restSettings
        } = action.settings;

        return {
            blogPostLink: blogPostLink,
            blogPostTitle: '',
            description: '',
            blogPostButtonText: blogPostButtonText,
            blogPostImages: null,
            blogPostDate: new Date(),
            showBlogWebsite: true,
            blogPostProvider: null,
            blogPostProviderId: null,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: blogPostButtonText,
                showButton: true,
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.blogPostLink = '';
            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.blogPostImages ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.blogPostImages?.[0] ?? null;
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings, actionSettings.blogPostLink) ?? null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.blogPostImages) {
                actionSettings.blogPostImages = actionSettings.blogPostImages.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.blogPostProvider !== null && actionSettings.blogPostProviderId !== null ?
                doppeExternalBlogPostProviderResolveBusinessEntityReference(
                    actionSettings.blogPostProvider,
                    actionSettings.blogPostProviderId
                ) : null
        ])
    }
});


export function populateBlogPostActionSettingsDefaultTexts(options: {
    blogPost: DoppeActionTypeBlogPost.ActionSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeBlogPost.ActionSettings {

    const {
        blogPost,
        pageContentLocales,
    } = options;

    const defaultButtonText = pageContentLocales["blogPost.actionSettings.buttonText"]
    const calculatedButtonText = doppeHideableValueGetVisibleValue(blogPost.ctaButton.text, defaultButtonText);

    return  {
        ...blogPost,
        blogPostTitle: doppeHideableValueGetValue(blogPost.blogPostTitle) !== '' ? blogPost.blogPostTitle : pageContentLocales["blogPost.actionSettings.title"],
        description: doppeHideableValueGetValue(blogPost.description) !== '' ? blogPost.description : doppeHideableValueCreateHidden(pageContentLocales["blogPost.actionSettings.description"]),
        blogPostButtonText: calculatedButtonText,
        ...getDefaultDoppeActionCtaButtonSettings({
            buttonText: calculatedButtonText !== '' ? calculatedButtonText : defaultButtonText,
            showButton: true,
        }),
    }
}

export function convertDoppeWixBlogPostToBlogPostSettings(blogPost: DoppeDtoBlogPost, settings?: DoppeActionTypeBlogPost.ActionSettings): DoppeActionTypeBlogPost.ActionSettings {

    return {
        showBlogWebsite: true,
        blogPostButtonText: '',
        ...getDefaultDoppeActionCtaButtonSettings({
            buttonText: '',
            showButton: true,
        }),
        ...settings,
        blogPostImages: doppeDtoBlogPostGetMedia(blogPost),
        blogPostLink: blogPost.blogPostPageUrl ?? '',
        blogPostTitle: blogPost?.title ?? '',
        description: blogPost.excerpt,
        blogPostProvider: DoppeExternalBlogPostProvider.Wix,
        blogPostProviderId: blogPost.id,
        blogPostDate: blogPost.firstPublishedDate ?? new Date(Date.now()),
    } satisfies DoppeActionTypeBlogPost.ActionSettings
}