import {DoppeActionLayout} from './doppe-action-layout';
import {DoppeActionListItemClickBehaviour} from './doppe-action-list-item-click-behaviour';
import {DoppeDtoAction, doppeDtoActionGetDefaultSearchListSettingsProps} from './doppe-dto-action';
import {WixImageResource, WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue
} from './doppe-hideable-value';
import {BymoPageActionMediaViewLayout, BymoPageActionMediaViewSettings} from '../bymo-pages';
import {
    assertDefined,
    iterableMapToArray,
    objectForEachValue,
    OmitStrict,
    PartiallyRequired,
    PickByValueExact,
    Values
} from '@wix/devzai-utils-common';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from './doppe-action-cta-button-settings';
import {DoppeActionContentSection, doppeActionContentSectionMigration} from './doppe-action-content-section';
import {
    doppeActionContentSectionDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from './doppe-action-content-sections-settings';
import {doppeActionGetMediaViewDefaultSettingsMigration} from './doppe-action-media-view-settings';
import {DoppeActionCTAButton} from './doppe-action-cta-button';
import {DoppeActionCtaButtonsSettings} from './doppe-action-cta-buttons-settings';

export interface DoppeListActionSettings<ItemSettings extends DoppeListActionSettings.ListItemBaseSettings = any> {
    listDataItems: ItemSettings[];
    itemShowDescription: boolean;
    itemShowImage: boolean;
    itemShowTitle: boolean;
    itemShowTextOnImage: boolean;
    layout: DoppeActionLayout;
    latestNonPremiumLayout?: DoppeActionLayout;
    listSearchSettings: DoppeDtoAction.ListSearchSettings;
}

export const DoppeListActionSettingsContentSectionAutoValue = {
    ListTemplateSections: 'ListTemplateSections',
} as const;

export type DoppeListActionSettingsContentSectionAutoValue = Values<typeof DoppeListActionSettingsContentSectionAutoValue>;


export const DoppeListActionSettingsCtaButtonAutoValue = {
    ListTemplateButtons: 'ListTemplateButtons',
} as const;

export type DoppeListActionSettingsCtaButtonAutoValue = Values<typeof DoppeListActionSettingsCtaButtonAutoValue>;


export namespace DoppeListActionSettings {


    export interface ListItemBaseSettings {
        id: string;
        enabled: boolean;
        deleted: boolean;
        displayInSearch: boolean;
    }

    export type SectionListItem = DoppeActionContentSection | DoppeListActionSettingsContentSectionAutoValue;

    export type ListItemWithSections = {
        sectionsOverridesMap: Record<string, DoppeActionContentSection.Override>;
        sections: SectionListItem[];
        customSections: DoppeActionContentSection[];
    }

    export type CtaButtonListItem = DoppeActionCTAButton.MultipleButton | DoppeListActionSettingsCtaButtonAutoValue;

    export type ListItemWithCtaButtons = {
        ctaButtonsOverridesMap: Record<string, DoppeActionCTAButton.MultipleButtonOverride>;
        ctaButtons: CtaButtonListItem[];
        customCtaButtons: DoppeActionCTAButton.MultipleButton[];
    }

    export interface DeprecatedFields {
        showButton: boolean;
        buttonText: string;
        buttonUrl: string;
    }

    export type ResolveListItemSettings<
        T extends DoppeListActionSettings,
        K extends keyof T & string
    > = T[K] extends Array<infer U> ? U : never;

    export type ResolvePartialAndDeprecatedActionSettings<
        T extends DoppeListActionSettings,
        V extends {id: string},
        K extends keyof PickByValueExact<T, V[]> & string
    > = Partial<OmitStrict<T, K>> & {
        [key in K]?: PartiallyRequired<Partial<V>, 'id'>[]
    } & DoppeListActionSettings.DeprecatedFields;

    export interface WithExternalDataSourceSupport<T extends {}> {
        externalDataSource: null | T;
        externalDataSourceItemsCount: number;
    }

    export interface WithCtaButton  extends DoppeActionCtaButtonSettings {

    }

    export interface WithMainDetails  {
        mainImage: DoppeHideableValue<WixMediaResource | null>;
        mainMediaViewSettings: BymoPageActionMediaViewSettings;
        mainMediaViewLayout: BymoPageActionMediaViewLayout;
        mainTitle: DoppeHideableValue<string>;
    }

    export interface WithItemClickBehaviour  {
        itemClickBehaviour: DoppeActionListItemClickBehaviour;
    }
}

export function doppeListActionListItemWithSectionsMigration(overrides?: Partial<DoppeListActionSettings.ListItemWithSections>) {

    const sectionsOverridesMap = overrides?.sectionsOverridesMap ?? {};
    objectForEachValue(sectionsOverridesMap, (partialContentSection) => {

        const {
            type,
            content,
            ...resetPartialContentSection
        } = partialContentSection;

        return {
            type: type,
            content: {
                ...doppeActionContentSectionMigration(type),
                ...content
            },
            ...resetPartialContentSection
        } satisfies  DoppeActionContentSection.Override
    })

    return {
        customSections: overrides?.customSections ?  doppeHideableValueGetValue(overrides.customSections).map(listItemSection => {
            return doppeActionContentSectionDefaultValuesMigration(listItemSection)
        }) : [],
        sections: overrides?.sections?.map(listItemSection => {
            if (listItemSection === DoppeListActionSettingsContentSectionAutoValue.ListTemplateSections) {
                return listItemSection;
            }
            else {
                return doppeActionContentSectionDefaultValuesMigration(listItemSection)
            }
        }) ?? [],
        sectionsOverridesMap: sectionsOverridesMap,
    } satisfies DoppeListActionSettings.ListItemWithSections;
}

export function doppeListActionListItemCalculateItemSections(listSettings: DoppeActionContentSectionsSettings, itemSettings: DoppeListActionSettings.ListItemWithSections) {

    const sections : DoppeActionContentSection[] = [...itemSettings.customSections];

    itemSettings.sections.forEach(listItemSection => {
        if (listItemSection === DoppeListActionSettingsContentSectionAutoValue.ListTemplateSections) {
            listSettings.sections.forEach(listSection => {
                sections.push({
                    ...listSection,
                    ...itemSettings.sectionsOverridesMap[listSection.id],
                    isRequired: true,
                } as DoppeActionContentSection)
            });
        }
        else {
            sections.push({
                ...listItemSection,
                isRequired: false
            });
        }
    })

    return {
        sections: sections
    }
}

export function doppeListActionListItemEditItemSectionsForListItemSettings(items: DoppeActionContentSection[]) {


    const sectionsOverridesMap : Record<string, DoppeActionContentSection.Override> = {};

    items.forEach(sectionSettings => {
        if (sectionSettings.isRequired) {
            sectionsOverridesMap[sectionSettings.id] = {
                content: sectionSettings.content,
                isVisible: sectionSettings.isVisible,
                type: sectionSettings.type
            }
        }
    })

    const sections = iterableMapToArray(items, (sectionItem, skip, index) => {
        if (sectionItem.isRequired) {
            if (index == 0 || index > 0 && !items[index-1].isRequired) {
                return DoppeListActionSettingsContentSectionAutoValue.ListTemplateSections;
            }

            return skip;
        }
        else {
            return sectionItem;
        }
    })

    return {
        sections: sections,
        sectionsOverridesMap: sectionsOverridesMap,
    }
}


export function doppeListActionListItemWithCtaButtonsMigration(overrides: Partial<DoppeListActionSettings.ListItemWithCtaButtons>, hasSourceURL: boolean) {

    const ctaButtonsOverridesMap = overrides.ctaButtonsOverridesMap ?? {};
    objectForEachValue(ctaButtonsOverridesMap, (ctaButtonOverride) => {

        const ctaButtonSettings = assertDefined(doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
            ctaButton: ctaButtonOverride,
            hasSourceURL: hasSourceURL
        }));

        return {
            settings: ctaButtonSettings.ctaButton.settings,
            isVisible: ctaButtonOverride.isVisible
        } satisfies  DoppeActionCTAButton.MultipleButtonOverride
    })

    const customCtaButtonsValue = doppeHideableValueGetValue(overrides.customCtaButtons) ?? [];

    return {
        customCtaButtons: customCtaButtonsValue.map(listItemButton => {
            return assertDefined(doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: listItemButton,
                hasSourceURL: hasSourceURL
            })).ctaButton;
        }),
        ctaButtons: overrides.ctaButtons?.map(listItemButton => {
            if (listItemButton === DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons) {
                return listItemButton;
            }
            else {
                return assertDefined(doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                    ctaButton: listItemButton,
                    hasSourceURL: hasSourceURL
                })).ctaButton
            }
        }) ?? [],
        ctaButtonsOverridesMap: ctaButtonsOverridesMap,
    } satisfies DoppeListActionSettings.ListItemWithCtaButtons;
}

export function doppeListActionListItemCalculateItemButtons(ctaButtonsSettings: DoppeActionCtaButtonsSettings, itemSettings: DoppeListActionSettings.ListItemWithCtaButtons) {

    const ctaButtons : DoppeActionCTAButton.MultipleButton[] = [...itemSettings.customCtaButtons];

    itemSettings.ctaButtons.forEach(listItemCtaButton => {
        if (listItemCtaButton === DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons) {
            ctaButtonsSettings.ctaButtons.forEach(listCtaButton => {
                ctaButtons.push({
                    ...listCtaButton,
                    ...itemSettings.ctaButtonsOverridesMap[listCtaButton.id],
                } as DoppeActionCTAButton.MultipleButton)
            });
        }
        else {
            ctaButtons.push({
                ...listItemCtaButton,
            });
        }
    })

    return {
        ctaButtons: ctaButtons
    }
}

export function doppeListActionListItemEditItemCtaButtonsForListItemSettings(items: DoppeActionCTAButton.MultipleButton[]) {


    const ctaButtonsOverridesMap : Record<string, DoppeActionCTAButton.MultipleButtonOverride> = {};

    items.forEach(ctaButton => {
        if (ctaButton.isRequired) {
            ctaButtonsOverridesMap[ctaButton.id] = {
                settings: ctaButton.settings,
                isVisible: ctaButton.isVisible,
            }
        }
    })

    const ctaButtons = iterableMapToArray(items, (ctaButton, skip, index) => {
        if (ctaButton.isRequired) {
            if (index == 0 || index > 0 && !items[index-1].isRequired) {
                return DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons;
            }

            return skip;
        }
        else {
            return ctaButton;
        }
    })

    return {
        ctaButtons: ctaButtons,
        ctaButtonsOverridesMap: ctaButtonsOverridesMap,
    }
}




export function doppeListActionWithMainDetailsGetMainImage(settings: DoppeListActionSettings.WithMainDetails, listItemImage: WixMediaResource | null) {
    return doppeHideableValueIsVisibleAndNotEqualValue(settings.mainImage, null) ? settings.mainImage : listItemImage;

}

export function doppeListActionWithMainDetailsReplaceMainImage(actionSettings: DoppeListActionSettings.WithMainDetails, externalImagesUrlToImageResourceMap: Map<string, WixImageResource>) {
    const mainImage = doppeHideableValueGetValue(actionSettings.mainImage);
    if (mainImage) {
        actionSettings.mainImage = doppeHideableValueModifyValue(actionSettings.mainImage, () => {
            return wixMediaResourceReplaceExternalImage(mainImage, externalImagesUrlToImageResourceMap)
        })
    }
}

export function doppeListActionGetDefaultListSettings(overrides?: Partial<DoppeListActionSettings>) {

    const {
        ...restOverrides
    } = overrides ?? {}

    return {
        listDataItems: [],
        layout: DoppeActionLayout.Column,
        itemShowDescription: true,
        itemShowTitle: true,
        itemShowImage: true,
        itemShowTextOnImage: false,
        listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
        ...getDefaultDoppeActionCtaButtonSettings({
            buttonText: '',
            url: 'https://www.website.com',
            showButton: false,
        }),
        ...restOverrides
    } satisfies DoppeListActionSettings;
}

export function doppeListActionListItemGetDefaultListItemSettingsMigration(id: string, overrides?: Partial<DoppeListActionSettings.ListItemBaseSettings>) {
    return {
        id: id,
        enabled: true,
        deleted: false,
        displayInSearch: true,
        ...overrides
    } satisfies DoppeListActionSettings.ListItemBaseSettings;
}

export function doppeListActionGetItemClickBehaviourDefaultListSettings(overrides?: Partial<DoppeListActionSettings.WithItemClickBehaviour>) {
    return {
        itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
        ...overrides
    } satisfies DoppeListActionSettings.WithItemClickBehaviour;
}

export function doppeListActionGetWithExternalDataSourceDefaultListSettings(overrides?: Partial<DoppeListActionSettings.WithExternalDataSourceSupport<any>>) {
    return {
        externalDataSource: null,
        externalDataSourceItemsCount: 4,
        ...overrides
    } satisfies DoppeListActionSettings.WithExternalDataSourceSupport<any>;
}

export function doppeListActionGetMainDetailsDefaultSettings (
    options: {
        defaultTitle?: string;
    } = {},
    overrides?: Partial<DoppeListActionSettings.WithMainDetails>,
) {

    const {
        mainMediaViewLayout,
        mainMediaViewSettings,
        ...restOverrides
    } = overrides ?? {}

    return {
        mainImage: doppeHideableValueCreateHidden(null),
        mainTitle: doppeHideableValueCreateHidden(options.defaultTitle ?? ''),
        ...doppeActionGetMediaViewDefaultSettingsMigration(overrides ?? {}),
        ...restOverrides
    } satisfies DoppeListActionSettings.WithMainDetails;
}

export function convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(
    actionSettings: Partial<DoppeListActionSettings & DoppeListActionSettings.WithCtaButton & DoppeListActionSettings.DeprecatedFields>
) {
    return getDefaultDoppeActionCtaButtonSettings({
        buttonText: actionSettings.buttonText,
        url: actionSettings.buttonUrl ?? '',
        showButton: actionSettings.showButton !== undefined ? actionSettings.showButton : false,
    });
}


export function doppeListActionSettingsFilterHiddenListItemsFromActionView<T extends DoppeListActionSettings.ListItemBaseSettings>(items?: T[]) {
    return items?.filter(doppeListActionSettingsIsListItemVisibleInAction) ?? [];
}

export function doppeListActionSettingsFilterHiddenListItemsFromSearchResults<T extends DoppeListActionSettings.ListItemBaseSettings>(items?: T[]) {
    return items?.filter(doppeListActionSettingsIsListItemVisibleInSearchResults) ?? [];
}

export function doppeListActionSettingsIsListItemVisibleInAction<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return item.enabled && !item.deleted;
}

export function doppeListActionSettingsIsListItemHiddenInActionAndNotDeleted<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return !item.enabled && !item.deleted;
}

export function doppeListActionSettingsIsListItemVisibleInSearchResults<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return doppeListActionSettingsIsListItemVisibleInAction(item) && item.displayInSearch;
}

export function doppeListActionSettingsIsListItemIsDeleted<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return item.deleted;
}

export function doppeListActionSettingsIsListItemIsVisible<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return item.enabled;
}