import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, arrayRemoveNullValues, asArray, immerProduce, PartiallyOptional} from '@wix/devzai-utils-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionListItemClickBehaviour,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {DoppeActionTypeIframe} from '../doppe-action-type-iframe/doppe-action-type-iframe';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration
} from '../../client-server-common/types/doppe-action-cta-button-settings';

export namespace DoppeActionTypeEmbedPageList {

    export interface EmbedPageSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeIframe.ActionSettings {
        title: string;
        image: WixMediaResource[] | null;
        description: DoppeHideableValue<RichTextContent>;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<EmbedPageSettings>,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour
    {
    }

    export interface DeprecatedFields {
        links?: EmbedPageSettings[];
    }
}


export const doppeActionTypeEmbedPageList = doppeActionTypeDefineMetadata<DoppeActionTypeEmbedPageList.ActionSettings>({
    id: 'io.bymo.action/embed-page-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            ...restSettings
        } = action.settings;

        return {

            previewLinkButtonText: 'Continue',
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(link => {
                    return {
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(link.id),
                        ...link as PartiallyOptional<DoppeActionTypeEmbedPageList.EmbedPageSettings, 'enabled'>,
                    }
                }),
            }),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings({
                itemClickBehaviour: DoppeActionListItemClickBehaviour.OpenLink,
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: false,
    notSupportsRenderingPreviewItem: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.image ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.image?.[0] ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.listDataItems.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.image?.[0] ?? null,
                itemId: item.id,
                ribbon: doppeHideableValueCreateHidden(''),
                url: null,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const link of actionSettings.listDataItems) {
                if (link.image) {
                    link.image = asArray(link.image).map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
    hasNoLockInnerCTA: true,
});
