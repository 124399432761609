import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {urlRemoveQueryString, Values} from '@wix/devzai-utils-common';
import {DoppeHideableValue, doppeHideableValueGetValue, doppeHideableValueIsVisible} from '../../client-server-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    DoppeActionCtaButtonSettings,
    getDefaultDoppeActionCtaButtonSettings, doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration
} from '../../client-server-common/types/doppe-action-cta-button-settings';


export const SoundcloudWidgetSize = {
    Big: 'Big',
    Normal: 'Normal',
    Compact: 'Compact',
} as const;


export namespace DoppeActionTypeSoundCloud {

    export type Size = Values<typeof SoundcloudWidgetSize>;

    export interface ActionSettings extends DoppeActionCtaButtonSettings {
        url: string;
        size: Size;
        buttonText: DoppeHideableValue<string>;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
    }
}

export const doppeActionTypeSoundcloud = doppeActionTypeDefineMetadata<DoppeActionTypeSoundCloud.ActionSettings>({
    id: 'io.bymo.action/soundcloud',
    resolveActionSettings: action => {

        const {
            buttonText = '',
            ctaButton,
            ...restSettings
        } = action.settings;

        return {
            url: '',
            size: SoundcloudWidgetSize.Normal,
            title: 'Title',
            description: 'Description',
            buttonText: buttonText,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: doppeHideableValueGetValue(buttonText),
                showButton: doppeHideableValueIsVisible(buttonText),
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...restSettings
        }

    },
    hasNoGoalMeasuring: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    hasNoLockInnerCTA: true,
})

export function doppeValidateSoundcloudURL(url: string) {
    return doppeValidateSoundcloudTrackURL(url) || doppeValidateSoundcloudPlaylistURL(url);
}

export function doppeValidateSoundcloudTrackURL(url: string) {
    const trackPattern = /^https:\/\/soundcloud\.com\/[\w-]+\/[\w-]+$/;
    return trackPattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSoundcloudPlaylistURL(url: string) {
    const playlistPattern = /^https:\/\/soundcloud\.com\/[\w-]+\/sets\/[\w-]+$/;
    return playlistPattern.test(urlRemoveQueryString(url));
}