import {Values} from "../common-types";

export type DateTimeValue = Date | string | number;
export type SerializedDateTimeValue = string | number;

export const DateTimeUnit = {
    Second: 'second',
    Minute: 'minute',
    Hour: 'hour',
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Quarter: 'quarter',
    Year: 'year',
} as const;

export type DateTimeUnit = Values<typeof DateTimeUnit>;

export function dateAdd (date: DateTimeValue, unit: DateTimeUnit, value: number) {
    return new Date(dateGetUnixTimeMilliseconds(date) + dateTimeUnitGetNumberOfMilliseconds(unit) * value);
}

export function dateAddDays (date: DateTimeValue, numberOfDays: number) {
    return dateAdd(date, DateTimeUnit.Day, numberOfDays);
}

export function dateGetCurrentDateTime () {
    return new Date(Date.now());
}

export function dateGetCurrentUnixTimeSeconds(): number {
    return Math.floor(Date.now() / 1000);
}

export function dateGetCurrentDate () {
    return new Date(new Date().toISOString().slice(0, 10) + 'T00:00:00.000Z')
}

export function dateResolveFromDateTimeValue (date: DateTimeValue) : Date {
    return typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;
}

/**
 * Note: It's the same as Date.toJSON().
 */
export function dateConvertToIsoString (date: DateTimeValue) : string {
    return dateResolveFromDateTimeValue(date).toISOString();
}

export function dateGetUnixTimeMilliseconds (date: DateTimeValue) {
    return (new Date(date)).getTime();
}

export function dateTryParse (dateStr: string) : Date | undefined {
    const result = new Date(dateStr);

    return isNaN(result.getTime()) ? undefined : result;
}

export function dateGetCurrentDateTimeString () {
    return (new Date()).toJSON();
}

export function dateCalculateDateTimeDifference (
    referenceDate: DateTimeValue,
    options: {
        targetDate?: DateTimeValue;
        unit: DateTimeUnit;
    }
) {
    const {
        targetDate,
        unit
    } = options;

    const diff = dateGetUnixTimeMilliseconds(referenceDate) - dateGetUnixTimeMilliseconds(targetDate ?? new Date()); // Calculate the time difference in milliseconds

    return dateDiffGetRelativeTime(diff, unit);
}

export function dateDiffGetRelativeTime(diff: number, unit: DateTimeUnit) {
    return diff / dateTimeUnitGetNumberOfMilliseconds(unit);
}

export function dateTimeUnitGetNumberOfMilliseconds(unit: DateTimeUnit): number {

    switch (unit) {
        case DateTimeUnit.Second: {
            return 1000
        }
        case DateTimeUnit.Minute: {
            return 1000 * 60;
        }
        case DateTimeUnit.Hour: {
            return (1000 * 60 /* Minute */) * 60;
        }
        case DateTimeUnit.Day: {
            return ((1000 * 60 /* Minute */) * 60 /* Hour */) * 24
        }
        case DateTimeUnit.Month: {
            return dateTimeUnitGetNumberOfMilliseconds(DateTimeUnit.Day) * 30
        }
        case DateTimeUnit.Year: {
            return dateTimeUnitGetNumberOfMilliseconds(DateTimeUnit.Day) * 365
        }
        case DateTimeUnit.Week: {
            return dateTimeUnitGetNumberOfMilliseconds(DateTimeUnit.Day) * 7
        }
        case DateTimeUnit.Quarter: {
            return dateTimeUnitGetNumberOfMilliseconds(DateTimeUnit.Day) * 91
        }
    }
}




