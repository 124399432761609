import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, arrayRemoveNullValues, asArray, immerProduce, PartiallyOptional} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionListItemClickBehaviour,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {doppeHideableValueCreateHidden} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeActionTypeLinkView} from '../doppe-action-type-link-view/doppe-action-type-link-view';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';

export namespace DoppeActionTypeLinksList {

    export interface LinkSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeLinkView.ActionSettings {
        title: string;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<LinkSettings>,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour
    {
        previewLinkButtonText: string;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
    }

    export interface DeprecatedFields {
        links?: LinkSettings[];
    }
}


export const doppeActionTypeLinksList = doppeActionTypeDefineMetadata<DoppeActionTypeLinksList.ActionSettings>({
    id: 'io.bymo.action/links-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            ...restSettings
        } = action.settings;

        return {
            previewLinkButtonText: '',
            previewShowTitle: true,
            previewShowDescription: true,
            previewShowCoupon: true,
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(link => {

                    const {
                        linkButtonText = 'Continue',
                        ctaButton,
                        ...restLink
                    } = link;

                    return {
                        linkButtonText: linkButtonText,
                        description: doppeHideableValueCreateHidden(''),
                        couponCode: doppeHideableValueCreateHidden(''),
                        ribbon: doppeHideableValueCreateHidden(''),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: linkButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(link.id),
                        ...restLink as PartiallyOptional<DoppeActionTypeLinksList.LinkSettings, 'couponCode' | 'linkButtonText' | 'description' | 'ribbon' | 'ctaButton'>,
                        image: link.image ? asArray(link.image) : null,
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings({
                itemClickBehaviour: DoppeActionListItemClickBehaviour.OpenLink,
            }),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.image ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.image?.[0] ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.listDataItems.forEach(itemSettings => {
                itemSettings.url = ''
                doppeActionCtaButtonResetSettingsForLock(itemSettings);
            });

            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.listDataItems.length;
    },
    resolveCouponDataFromListItem: listItem => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(listItem.couponCode, ''),
            couponCode: doppeHideableValueGetVisibleValue(listItem.couponCode, ''),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(listItem.title, ''),
        }
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.image?.[0] ?? null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.url,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const link of actionSettings.listDataItems) {
                if (link.image) {
                    link.image = asArray(link.image).map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});
