import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    iterableMapToArray,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {
    convertDoppeWixBookingServiceToServiceSettings,
    DoppeActionTypeService,
    populateServiceActionSettingsDefaultTexts
} from '../doppe-action-type-service/doppe-action-type-service';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeDtoBookingService,
    doppeHideableValueCreateHidden,
    doppeHideableValueEnsureHidden,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsHidden,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {
    doppeExternalServiceProviderResolveBusinessEntityReference
} from '../../client-server-common/types/doppe-external-services';
import {
    DoppeServicesExternalDataSource,
    doppeServicesExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-services-external-data-source';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionSettingsCtaDefaultSettings
} from '../../client/doppe-action-types-client-common/doppe-action-settings-cta-client-common';


export namespace DoppeActionTypeServicesList {

    export interface ServiceSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeService.ActionSettings {
        serviceTitle: string;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<ServiceSettings>,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeServicesExternalDataSource> {
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
        previewShowPrice: boolean;
        previewServiceButtonText: string;
    }

    export interface DeprecatedFields {
        services?: ServiceSettings[];
    }
}


export const doppeActionTypeServicesList = doppeActionTypeDefineMetadata<DoppeActionTypeServicesList.ActionSettings>({
    id: 'io.bymo.action/services-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            ...restSettings
        } = action.settings;

        return {
            itemShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewServiceButtonText: '',
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(service => {

                    const {
                        serviceButtonText,
                        ctaButton,
                        ...restService
                    } = service;

                    return {
                        serviceProviderId: null,
                        serviceProvider: null,
                        ribbon: doppeHideableValueCreateHidden('SALE'),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: serviceButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(service.id),
                        ...restService as PartiallyOptional<DoppeActionTypeServicesList.ServiceSettings, 'serviceProvider' | 'serviceProviderId' | 'ribbon' | 'ctaButton'>
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings({
                defaultTitle: ''
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(article => {
            return article.serviceImages ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.serviceImages?.[0] ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.listDataItems.forEach(itemSettings => {
                itemSettings.servicePageLink = ''
                doppeActionCtaButtonResetSettingsForLock(itemSettings);
            });

            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeServicesExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (plan, skip) => {
                return plan.serviceProvider !== null && plan.serviceProviderId !== null ?
                    doppeExternalServiceProviderResolveBusinessEntityReference(
                        plan.serviceProvider,
                        plan.serviceProviderId
                    ) : skip
            });
        }
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : actionSettings.listDataItems.length;
    },
    resolveCouponDataFromListItem: listItem => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(listItem.couponCode, ''),
            couponCode: doppeHideableValueGetVisibleValue(listItem.couponCode, ''),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(listItem.title, ''),
        }
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.serviceTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.serviceImages ? asArray(item.serviceImages)[0] : null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.servicePageLink,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const service of actionSettings.listDataItems) {
                if (service.serviceImages) {
                    service.serviceImages = service.serviceImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});

export function populateServicesActionServiceSettingsDefaultTexts(options: {
    service: DoppeActionTypeServicesList.ServiceSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}): DoppeActionTypeServicesList.ServiceSettings {

    const {
        pageContentLocales,
        service,
    } = options;

    const normalizedContent = populateServiceActionSettingsDefaultTexts({
        pageContentLocales: pageContentLocales,
        service: service
    });


    const defaultButtonText = doppeHideableValueModifyValue(normalizedContent.ctaButton.text, (value) => {
        return value ?? pageContentLocales["service.actionSettings.buttonText"]
    });

    const calculatedDefaultValue = doppeHideableValueEnsureHidden(defaultButtonText);

    return {
        ...service,
        ...normalizedContent,
        serviceTitle: doppeHideableValueIsHidden(normalizedContent.serviceTitle) ? '' : normalizedContent.serviceTitle,
        serviceButtonText: calculatedDefaultValue,
        ...doppeActionSettingsCtaDefaultSettings(pageContentLocales,{
            showButton: true,
            buttonText: calculatedDefaultValue
        })
    }
}

export function convertDoppeWixServiceToServicesSettings(
    service: DoppeDtoBookingService,
): DoppeActionTypeServicesList.ServiceSettings {
    return {
        ...convertDoppeWixBookingServiceToServiceSettings(service),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(service.id),
        couponCode: doppeHideableValueCreateHidden(''),
        ribbon: doppeHideableValueCreateHidden(''),
        serviceButtonText:  doppeHideableValueCreateHidden(''),
        ...getDefaultDoppeActionCtaButtonSettings({
            showButton: true,
            buttonText: doppeHideableValueCreateHidden('')
        }),
    }
}