import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeActionWidgetOpeningMode,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {asArray, immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    getDefaultDoppeActionCtaButtonSettings, doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';


export namespace DoppeActionTypeLinkView {

    export interface ActionSettings extends DoppeActionCtaButtonSettings {
        url: string;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        image: WixMediaResource[] | null;
        linkButtonText: DoppeHideableValue<string>;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeLinkView = doppeActionTypeDefineMetadata<DoppeActionTypeLinkView.ActionSettings>({
    id: 'io.bymo.action/link-view',
    resolveActionSettings: action => {

        const {
            url = '#',
            linkButtonText = 'Continue',
            ctaButton,
            ...restSettings
        } = action.settings;

        return {
            url: url,
            title: 'Link title',
            description: doppeHideableValueCreateHidden('Link description'),
            image: null,
            linkButtonText: linkButtonText,
            couponCode: doppeHideableValueCreateHidden('Coupon code'),
            ribbon: doppeHideableValueCreateHidden('SALE'),
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: linkButtonText,
                showButton: true,
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...restSettings
        }
    },
    isLinkOpener: true,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: (actionContext) => actionContext.action.widgetOpeningMode === DoppeActionWidgetOpeningMode.OpenCTA ? false : true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image) : [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image)[0] : null
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings, actionSettings.url) ?? null;
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.couponCode, ''),
            couponCode: doppeHideableValueGetValue(actionSettings.couponCode),
            couponDescription: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.description, '') ? actionSettings.description : '',
            couponTitle: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.title, '') ? actionSettings.title : '',
        }
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.image) {
                actionSettings.image = asArray(actionSettings.image).map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.url = '';
            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
})