import {
    evaluateFunction,
    HtmlObjectHorizontalPosition,
    HtmlObjectVerticalPosition,
    uuidCreate,
    Values
} from '@wix/devzai-utils-common';
import {DoppeHideableValue, doppeHideableValueCreateHidden} from './doppe-hideable-value';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeBymoPageVideoSource} from './doppe-bymo-page-video-source';
import {
    BymoPageActionMediaLayout,
    BymoPageActionMediaSize,
    BymoPageActionMediaSizing,
    BymoPageActionMediaViewSettings
} from '../bymo-pages';


export const DoppeActionContentSectionType = {
    RichContent: 'RichContent',
    Image: 'Image',
    Video: 'Video',
    Gallery: 'Gallery',
    PropertyDetails: 'PropertyDetails',
} as const;

export type DoppeActionContentSectionType = Values<typeof DoppeActionContentSectionType>;

export namespace DoppeActionContentSectionGallery {

    export type GalleryItem = {
        media: WixMediaResource | null;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
    }
}


export type DoppeActionContentSection =
    | DoppeActionContentSection.RichContent
    | DoppeActionContentSection.Image
    | DoppeActionContentSection.Video
    | DoppeActionContentSection.Gallery
    | DoppeActionContentSection.PropertyDetails;


export namespace DoppeActionContentSection{

    export type Override = Pick<DoppeActionContentSection, 'isVisible' | 'content' | 'type'>

    type BaseType<T extends DoppeActionContentSectionType> = {
        type: T;
        title: string;
        isVisible: boolean;
        layoutSettings: DoppeActionContentSectionLayoutSettings;
        isRequired: boolean;
        id: string;
    }

    export type RichContent = BaseType<typeof DoppeActionContentSectionType.RichContent> & {
        content: {
            richText: RichTextContent
        };
    };

    export type Image = BaseType<typeof DoppeActionContentSectionType.Image> & {
        content: {
            image: WixMediaResource | null;
            imageViewSettings: BymoPageActionMediaViewSettings;
        }
    };

    export type Video = BaseType<typeof DoppeActionContentSectionType.Video> & {
        content: {
            video: DoppeBymoPageVideoSource | null;
            autoPlay: boolean;
        }
    };

    export type Gallery = BaseType<typeof DoppeActionContentSectionType.Gallery> & {
        content: {
            mediaList: DoppeActionContentSectionGallery.GalleryItem[];
            mediaViewSettings: BymoPageActionMediaViewSettings;
        }
    };

    export type PropertyDetails = BaseType<typeof DoppeActionContentSectionType.PropertyDetails> & {
        content: {
            bedroomsNumber: DoppeHideableValue<number>;
            bathroomsNumber: DoppeHideableValue<number>;
            houseArea: DoppeHideableValue<string>;
            landArea: DoppeHideableValue<string>;
            floors: DoppeHideableValue<number>;
            yearBuilt: DoppeHideableValue<number>;
        }
    };

}



export const DoppeActionContentSectionLayoutType = {
    Inline: 'Inline',
    Collapsable: 'Collapsable',
} as const;

export type DoppeActionContentSectionLayoutType = Values<typeof DoppeActionContentSectionLayoutType>;

export type DoppeActionContentSectionLayoutSettings =
    | DoppeActionContentSectionLayoutSettings.Inline
    | DoppeActionContentSectionLayoutSettings.Collapsable;

export namespace DoppeActionContentSectionLayoutSettings {

    type BaseType<T extends DoppeActionContentSectionLayoutType> = {
        type: T;
    }

    export type Inline = BaseType<typeof DoppeActionContentSectionLayoutType.Inline> & {
        hideHeader: boolean;
    };

    export type Collapsable = BaseType<typeof DoppeActionContentSectionLayoutType.Collapsable> & {
        isOpenByDefault: boolean;
    };
}

export function doppeActionSectionIsVisible(section: DoppeActionContentSection) {
    return section.isVisible;
}

export function doppeActionContentSectionLayoutCreateDefault(type: DoppeActionContentSectionLayoutType) {

    switch (type) {
        case DoppeActionContentSectionLayoutType.Inline: {
            return {
                type: DoppeActionContentSectionLayoutType.Inline,
                hideHeader: false,
            } satisfies DoppeActionContentSectionLayoutSettings.Inline;
        }
        case DoppeActionContentSectionLayoutType.Collapsable: {
            return {
                type: DoppeActionContentSectionLayoutType.Collapsable,
                isOpenByDefault: false,
            } satisfies DoppeActionContentSectionLayoutSettings.Collapsable;
        }
    }
}


export function doppeActionContentSectionMigration(type: DoppeActionContentSectionType) {

    const baseSettings = {
        isVisible: true,
        id: uuidCreate(),
        layoutSettings: {
            type: DoppeActionContentSectionLayoutType.Collapsable,
            isOpenByDefault: false
        },
        isRequired: false,
        title: ''
    }

    const partialSection = doppeActionContentSectionPartialMigration(type);

    switch (partialSection.type) {
        case DoppeActionContentSectionType.RichContent: {
            return {
                type: DoppeActionContentSectionType.RichContent,
                content: partialSection.content,
                ...baseSettings
            } satisfies DoppeActionContentSection.RichContent;
        }
        case DoppeActionContentSectionType.Image: {
            return {
                type: DoppeActionContentSectionType.Image,
                content: partialSection.content,
                ...baseSettings
            } satisfies DoppeActionContentSection.Image;
        }
        case DoppeActionContentSectionType.Video: {
            return {
                type: DoppeActionContentSectionType.Video,
                content: partialSection.content,
                ...baseSettings
            } satisfies DoppeActionContentSection.Video;
        }
        case DoppeActionContentSectionType.Gallery: {
            return {
                type: DoppeActionContentSectionType.Gallery,
                content: partialSection.content,
                ...baseSettings
            } satisfies DoppeActionContentSection.Gallery;
        }
        case DoppeActionContentSectionType.PropertyDetails: {
            return {
                type: DoppeActionContentSectionType.PropertyDetails,
                content: partialSection.content,
                ...baseSettings
            } satisfies DoppeActionContentSection.PropertyDetails
        }
    }
}

export function doppeActionContentSectionPartialMigration(type: DoppeActionContentSectionType) {

    const xxx = evaluateFunction(() => {
        switch (type) {
            case DoppeActionContentSectionType.RichContent: {
                return {
                    type: DoppeActionContentSectionType.RichContent,
                    content: {
                        richText: ''
                    },
                } satisfies Pick<DoppeActionContentSection.RichContent, 'content' | 'type'>;
            }
            case DoppeActionContentSectionType.Image: {
                return {
                    type: DoppeActionContentSectionType.Image,
                    content: {
                        image: null,
                        imageViewSettings: {
                            mediaLayout: BymoPageActionMediaLayout.Cropped,
                            mediaSizing: BymoPageActionMediaSizing.Custom,
                            size: BymoPageActionMediaSize.Square,
                            alignment: {
                                horizontal: HtmlObjectHorizontalPosition.Center,
                                vertical: HtmlObjectVerticalPosition.Center
                            }
                        },
                    },
                } satisfies Pick<DoppeActionContentSection.Image, 'content' | 'type'>;
            }
            case DoppeActionContentSectionType.Video: {
                return {
                    type: DoppeActionContentSectionType.Video,
                    content: {
                        video: null,
                        autoPlay: true,
                    }
                } satisfies Pick<DoppeActionContentSection.Video, 'content' | 'type'>;
            }
            case DoppeActionContentSectionType.Gallery: {
                return {
                    type: DoppeActionContentSectionType.Gallery,
                    content: {
                        mediaList: [{
                            media: null,
                            title: doppeHideableValueCreateHidden(''),
                            description: doppeHideableValueCreateHidden(''),
                        }],
                        mediaViewSettings: {
                            mediaLayout: BymoPageActionMediaLayout.Cropped,
                            mediaSizing: BymoPageActionMediaSizing.Custom,
                            size: BymoPageActionMediaSize.Square,
                            alignment: {
                                horizontal: HtmlObjectHorizontalPosition.Center,
                                vertical: HtmlObjectVerticalPosition.Center
                            }
                        },
                    }
                } satisfies Pick<DoppeActionContentSection.Gallery, 'content' | 'type'>;
            }
            case DoppeActionContentSectionType.PropertyDetails: {
                return {
                    type: DoppeActionContentSectionType.PropertyDetails,
                    content: {
                        bedroomsNumber: doppeHideableValueCreateHidden(3),
                        bathroomsNumber: doppeHideableValueCreateHidden(5),
                        floors: doppeHideableValueCreateHidden(1),
                        yearBuilt: doppeHideableValueCreateHidden(2000),
                        houseArea: doppeHideableValueCreateHidden(''),
                        landArea: doppeHideableValueCreateHidden(''),
                    }
                } satisfies Pick<DoppeActionContentSection.PropertyDetails, 'content' | 'type'>
            }
        }
    })

    return xxx;
}
