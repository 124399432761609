import {
    DoppeActionContentSection,
    DoppeActionContentSectionLayoutType,
    DoppeActionContentSectionType
} from './doppe-action-content-section';
import {HtmlObjectHorizontalPosition, HtmlObjectVerticalPosition, uuidCreate} from '@wix/devzai-utils-common';
import {BymoPageActionMediaLayout, BymoPageActionMediaSize, BymoPageActionMediaSizing} from '../bymo-pages';
import {doppeHideableValueCreateHidden} from './doppe-hideable-value';


export type DoppeActionContentSectionsSettings = {
    sections: DoppeActionContentSection[];
}


export function doppeActionContentSectionSettingsGetDefaultSettings(type: DoppeActionContentSectionType) {

    const baseSettings = {
        isVisible: true,
        id: uuidCreate(),
        layoutSettings: {
            type: DoppeActionContentSectionLayoutType.Collapsable,
            isOpenByDefault: false
        },
        isRequired: false,
    }

    switch (type) {
        case DoppeActionContentSectionType.RichContent: {
            return {
                type: DoppeActionContentSectionType.RichContent,
                title: '',
                content: {
                    richText: ''
                },
                ...baseSettings
            } satisfies DoppeActionContentSection.RichContent;
        }
        case DoppeActionContentSectionType.Image: {
            return {
                type: DoppeActionContentSectionType.Image,
                title: '',
                content: {
                    image: null,
                    imageViewSettings: {
                        mediaLayout: BymoPageActionMediaLayout.Cropped,
                        mediaSizing: BymoPageActionMediaSizing.Custom,
                        size: BymoPageActionMediaSize.Square,
                        alignment: {
                            horizontal: HtmlObjectHorizontalPosition.Center,
                            vertical: HtmlObjectVerticalPosition.Center
                        }
                    },
                },
                ...baseSettings
            } satisfies DoppeActionContentSection.Image;
        }
        case DoppeActionContentSectionType.Video: {
            return {
                type: DoppeActionContentSectionType.Video,
                title: '',
                content: {
                    video: null,
                    autoPlay: true,
                },
                ...baseSettings
            } satisfies DoppeActionContentSection.Video;
        }
        case DoppeActionContentSectionType.Gallery: {
            return {
                type: DoppeActionContentSectionType.Gallery,
                title: '',
                content: {
                    mediaList: [{
                        media: null,
                        title: doppeHideableValueCreateHidden(''),
                        description: doppeHideableValueCreateHidden(''),
                    }],
                    mediaViewSettings: {
                        mediaLayout: BymoPageActionMediaLayout.Cropped,
                        mediaSizing: BymoPageActionMediaSizing.Custom,
                        size: BymoPageActionMediaSize.Square,
                        alignment: {
                            horizontal: HtmlObjectHorizontalPosition.Center,
                            vertical: HtmlObjectVerticalPosition.Center
                        }
                    },
                },
                ...baseSettings
            } satisfies DoppeActionContentSection.Gallery;
        }
        case DoppeActionContentSectionType.PropertyDetails: {
            return {
                type: DoppeActionContentSectionType.PropertyDetails,
                title: '',
                content: {
                    bedroomsNumber: doppeHideableValueCreateHidden(3),
                    bathroomsNumber: doppeHideableValueCreateHidden(5),
                    floors: doppeHideableValueCreateHidden(1),
                    yearBuilt: doppeHideableValueCreateHidden(2000),
                    houseArea: doppeHideableValueCreateHidden(''),
                    landArea: doppeHideableValueCreateHidden(''),
                },
                ...baseSettings
            } satisfies DoppeActionContentSection.PropertyDetails
        }
    }
}


export function doppeActionContentSectionSettingsDefaultValuesMigration(sectionsSettings: Partial<DoppeActionContentSectionsSettings>) {
    return {
        sections: sectionsSettings?.sections?.map(doppeActionContentSectionDefaultValuesMigration) ?? []
    } satisfies DoppeActionContentSectionsSettings
}


export function doppeActionContentSectionDefaultValuesMigration(contentSection: Partial<DoppeActionContentSection>) {
    const defaultSettings = doppeActionContentSectionSettingsGetDefaultSettings(contentSection.type ?? DoppeActionContentSectionType.RichContent);

    const {
        layoutSettings,
        content,
        ...restDefaultSettings
    } = defaultSettings

    const {
        layoutSettings: overrideLayoutSettings,
        content: overrideContent,
        ...restOverrideSettings
    } = contentSection

    return {
        ...restDefaultSettings,
        ...restOverrideSettings,
        layoutSettings: {
            ...layoutSettings,
            ...overrideLayoutSettings
        },
        content: {
            ...content,
            ...overrideContent
        }
    } as DoppeActionContentSection;
}
