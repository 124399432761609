import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeHideableValue, doppeHideableValueGetValue, doppeHideableValueIsVisible} from '../../client-server-common';
import {Values} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    DoppeActionCtaButtonSettings, doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';


export const AppleMusicWidgetSize = {
    Normal: 'Normal',
    Compact: 'Compact',
} as const;

export namespace DoppeActionTypeAppleMusic {

    export type Size = Values<typeof AppleMusicWidgetSize>;

    export interface ActionSettings extends DoppeActionCtaButtonSettings{
        url: string;
        size: Size;
        buttonText: DoppeHideableValue<string>;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
    }
}

export const doppeActionTypeAppleMusic = doppeActionTypeDefineMetadata<DoppeActionTypeAppleMusic.ActionSettings>({
    id: 'io.bymo.action/apple-music',
    resolveActionSettings: action => {

        const {
            buttonText = '',
            ctaButton,
            ...restSettings
        } = action.settings;

        return {
            url: '',
            buttonText: buttonText,
            title: 'Title',
            description: 'Description',
            size: AppleMusicWidgetSize.Compact,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: doppeHideableValueGetValue(buttonText),
                showButton: doppeHideableValueIsVisible(buttonText),
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...restSettings
        }

    },
    hasNoGoalMeasuring: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    hasNoLockInnerCTA: true,
})

