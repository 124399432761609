import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common/types/doppe-hideable-value';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    OmitStrict,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {
    DoppeActionLayout,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration, doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    DoppeListActionSettings,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage, doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
} from '../../client-server-common';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    doppeActionCtaButtonsResolveMainURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeActionTypeProperty} from '../doppe-action-type-property/doppe-action-type-property';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    doppeActionGetMediaViewDefaultSettingsMigration
} from '../../client-server-common/types/doppe-action-media-view-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';


export namespace DoppeActionTypePropertiesList {

    export interface PropertyItemSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemWithCtaButtons,
        OmitStrict<DoppeActionTypeProperty.ActionSettings, 'sections' | 'ctaButtons'> {
        propertyName: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<DoppeActionTypePropertiesList.PropertyItemSettings>,
        DoppeActionContentSectionsSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeActionCtaButtonsSettings,
        DoppeListActionSettings.WithItemClickBehaviour {
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowAddress: boolean;
    }

}


export const doppeActionTypePropertiesList = doppeActionTypeDefineMetadata<DoppeActionTypePropertiesList.ActionSettings>({
    id: 'co.hopp.action/properties-list',
    resolveActionSettings: action => {
        const {
            sections,
            listDataItems = [],
            ctaButtons,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            ...restSettings
        } = action.settings;

        return {
            itemShowPrice: true,
            previewShowTitle: true,
            previewShowDescription: true,
            previewShowAddress: true,
            previewShowPrice: true,
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Column,
                listDataItems: listDataItems.map(property => {

                    const {
                        ctaButtons,
                        ctaButtonsOverridesMap,
                        customCtaButtons,
                        sections,
                        customSections,
                        sectionsOverridesMap,
                        mainMediaViewSettings,
                        mainMediaViewLayout,
                        id,
                        deleted,
                        displayInSearch,
                        enabled,
                        ...restProduct
                    } = property;

                    return {
                        ...doppeActionGetMediaViewDefaultSettingsMigration(property),
                        ...doppeListActionListItemWithCtaButtonsMigration(property, false),
                        ...doppeListActionListItemWithSectionsMigration(property),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(property.id, property),
                        ...restProduct as PartiallyOptional<DoppeActionTypePropertiesList.PropertyItemSettings, 'mainMediaViewSettings' | 'mainMediaViewLayout' | 'ctaButtons' | 'sections' | 'sectionsOverridesMap' | 'ctaButtonsOverridesMap' |'customCtaButtons' | 'customSections'>
                    }
                }),
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: false,
    isFormAction: false,
    isListItemLinkOpener: false,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.media ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.media?.[0] ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings) ?? null;
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.listDataItems.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.propertyName,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.media ? asArray(item.media)[0] : null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: null,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.listDataItems) {
                if (item.media) {
                    const images = asArray(item.media);
                    item.media = images.map(image => {
                        return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    });
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});
