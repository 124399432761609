import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeActionPriceData,
    DoppeDtoStoreProduct,
    doppeDtoStoreProductPriceDataConvertToActionPriceData,
    DoppeExternalProductProvider,
    doppeExternalProductProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue, doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue
} from '../../client-server-common';
import {
    WixMediaResource,
    wixMediaResourceReplaceExternalImage,
    wixProductMediaTryConvertToWixMediaResource
} from '@wix/devzai-common-wix';
import {DoppeActionTypeProductViewerLang} from './doppe-action-type-product-viewer.lang';
import {arrayRemoveNullValues, asArray, immerProduce, stringIsNotNullableOrWhiteSpace} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    getDefaultDoppeActionCtaButtonSettings, doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';

export namespace DoppeActionTypeProduct {

    export interface ActionSettings extends DoppeActionCtaButtonSettings{
        productPageLink: string;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        productTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        productButtonText: DoppeHideableValue<string>;
        productImage: WixMediaResource[] | WixMediaResource | null;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        productProvider: DoppeExternalProductProvider | null;
        productProviderId: string | null;
    }
}

export const doppeActionTypeProduct = doppeActionTypeDefineMetadata<DoppeActionTypeProduct.ActionSettings>({
    id: 'io.bymo.action/product',
    resolveActionSettings: action => {

        const {
            productButtonText = DoppeActionTypeProductViewerLang.defaultTexts.productButtonText,
            productPageLink = '',
            ctaButton,
            ...restSettings
        } = action.settings;

        return {
            productPageLink: productPageLink,
            priceData: {
                actualPrice: DoppeActionTypeProductViewerLang.defaultTexts.price,
            },
            productTitle: doppeHideableValueCreateHidden(DoppeActionTypeProductViewerLang.defaultTexts.productTitle),
            description: DoppeActionTypeProductViewerLang.defaultTexts.description,
            productButtonText: productButtonText,
            productImage: null,
            couponCode: doppeHideableValueCreateHidden('CouponCode'),
            ribbon: doppeHideableValueCreateHidden('SALE'),
            productProvider: null,
            productProviderId: null,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: productButtonText,
                showButton: true,
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues(asArray(actionSettings.productImage));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.productImage ? asArray(actionSettings.productImage)[0] ?? null : null;
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings, actionSettings.productPageLink) ?? null;
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.couponCode, ''),
            couponCode: doppeHideableValueGetValue(actionSettings.couponCode),
            couponDescription: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.description, '') ? actionSettings.description : '',
            couponTitle: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.productTitle, '') ? actionSettings.productTitle : '',
        }
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.productProvider !== null && actionSettings.productProviderId !== null ?
                doppeExternalProductProviderResolveBusinessEntityReference(
                    actionSettings.productProvider,
                    actionSettings.productProviderId
                ) :
                null
        ])
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.productImage) {
                const images = asArray(actionSettings.productImage);
                actionSettings.productImage = images.map(image => {
                    return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                })
            }
        })
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.productPageLink = '';
            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
});

export function doppeActionTypeProductResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeProduct.ActionSettings,
    storeProduct: DoppeDtoStoreProduct
): DoppeActionTypeProduct.ActionSettings {
    return {
        ...actionSettings,
        priceData: doppeHideableValueModifyValue(
            actionSettings.priceData,
            doppeDtoStoreProductPriceDataConvertToActionPriceData(storeProduct.price)
        ),
        productPageLink: stringIsNotNullableOrWhiteSpace(storeProduct.productPageUrl) ?
            storeProduct.productPageUrl : 'about:blank',
    }
}

export function populateProductActionSettingsDefaultTexts(options: {
    product: DoppeActionTypeProduct.ActionSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeProduct.ActionSettings {

    const {
        product,
        pageContentLocales,
    } = options;

    const calculatedButtonText = doppeHideableValueGetVisibleValue(product.ctaButton.text, pageContentLocales["product.actionSettings.productButtonText"]);

    return  {
        ...product,
        priceData: doppeHideableValueGetValue(product.priceData).actualPrice !== '' ? product.priceData : {
            actualPrice: pageContentLocales["product.actionSettings.price"],
            discountPrice: ''
        },
        productTitle: doppeHideableValueGetValue(product.productTitle) !== '' ? product.productTitle : pageContentLocales["product.actionSettings.title"],
        description: doppeHideableValueGetValue(product.description) !== '' ? product.description : doppeHideableValueCreateHidden(pageContentLocales["product.actionSettings.description"]),
        productButtonText: calculatedButtonText,
        couponCode: doppeHideableValueGetValue(product.couponCode) !== '' ? product.couponCode : doppeHideableValueCreateHidden(pageContentLocales["product.actionSettings.couponCode"]),
        ribbon: doppeHideableValueGetValue(product.ribbon) !== '' ? product.ribbon : doppeHideableValueCreateHidden(pageContentLocales["product.actionSettings.ribbon"]),
        ...getDefaultDoppeActionCtaButtonSettings({
            buttonText: calculatedButtonText !== '' ? calculatedButtonText : pageContentLocales["product.actionSettings.productButtonText"],
            showButton: true,
        }),
    }
}

export function convertDoppeWixProductToProductSettings(options: {
    product: DoppeDtoStoreProduct, productSettings?: DoppeActionTypeProduct.ActionSettings
}) {

    const {
        product,
        productSettings,
    } = options;

    const productImages = product.media?.items ?
        arrayRemoveNullValues(product.media?.items.map(item => wixProductMediaTryConvertToWixMediaResource(item))) : null;

    const productTitle = product?.name;

    return {
        ribbon: doppeHideableValueCreateHidden(''),
        couponCode: doppeHideableValueCreateHidden(''),
        productButtonText: '',
        ...getDefaultDoppeActionCtaButtonSettings({
            buttonText: '',
            showButton: true,
        }),
        ...productSettings,
        productImage: productImages,
        productPageLink: product.productPageUrl ?? productSettings?.productPageLink ?? '',
        priceData: doppeDtoStoreProductPriceDataConvertToActionPriceData(product.price),
        productTitle: productTitle,
        description: product.descriptionPlainText,
        productProvider: DoppeExternalProductProvider.Wix,
        productProviderId: product.id,
    } satisfies DoppeActionTypeProduct.ActionSettings
}