import {
    BymoPageActionMediaLayout,
    BymoPageActionMediaSize,
    BymoPageActionMediaSizing,
    BymoPageActionMediaViewLayout,
    BymoPageActionMediaViewSettings
} from '../bymo-pages';
import {HtmlObjectHorizontalPosition, HtmlObjectVerticalPosition} from '@wix/devzai-utils-common';

export interface DoppeActionMediaViewSettings {
    mainMediaViewSettings: BymoPageActionMediaViewSettings;
    mainMediaViewLayout: BymoPageActionMediaViewLayout;
}


export function doppeActionGetMediaViewDefaultSettingsMigration (
    overrides?: Partial<DoppeActionMediaViewSettings>,
) {

    const {
        mainMediaViewSettings,
        ...restOverrides
    } = overrides ?? {}

    return {
        mainMediaViewSettings: {
            mediaLayout: BymoPageActionMediaLayout.Cropped,
            mediaSizing: BymoPageActionMediaSizing.Custom,
            size: BymoPageActionMediaSize.Ratio3By2,
            alignment: {
                horizontal: HtmlObjectHorizontalPosition.Center,
                vertical: HtmlObjectVerticalPosition.Center
            },
            ...mainMediaViewSettings,
        },
        mainMediaViewLayout: BymoPageActionMediaViewLayout.FullWidth,
        ...restOverrides
    } satisfies DoppeActionMediaViewSettings;
}

