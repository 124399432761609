import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common/types/doppe-hideable-value';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeCheckoutActionSettings,
    getDefaultDoppeCheckoutSettingsForAction,
    prepareCheckoutSettingsForViewer,
} from '../../client-server-common/types/doppe-checkout-action-settings';
import {arrayFlatten, arrayRemoveNullValues, asArray, immerProduce, PartiallyOptional} from '@wix/devzai-utils-common';
import {DoppeActionTypeRequest} from '../doppe-action-type-request/doppe-action-type-request';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeAutomationTriggers,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage,
    doppePremiumFeatureIsDisabled,
    DoppePremiumFeatures,
} from '../../client-server-common';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration
} from '../../client-server-common/types/doppe-action-cta-button-settings';


export namespace DoppeActionTypeRequests {

    export interface RequestSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeRequest.RequestOption {
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<DoppeActionTypeRequests.RequestSettings>,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeCheckoutActionSettings {
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewCheckoutButtonText: string;
    }

    export interface DeprecatedFields {
        requestOptions?: DoppeActionTypeRequests.RequestSettings[];
    }

}


export const doppeActionTypeRequests = doppeActionTypeDefineMetadata<DoppeActionTypeRequests.ActionSettings>({
    id: 'co.hopp.action/request',
    resolveActionSettings: action => {
        const {
            checkoutSettings,
            ctaButton,
            listDataItems = [],
            previewCheckoutButtonText = '',
            ...restSettings
        } = action.settings;

        return {
            itemShowPrice: true,
            previewShowTitle: true,
            previewShowDescription: true,
            previewCheckoutButtonText: previewCheckoutButtonText,
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(requestOption => {
                    return {
                        requestsInstructions: doppeHideableValueCreateHidden(''),
                        ribbon: doppeHideableValueCreateHidden(''),
                        showNotesInput: true,
                        previewCheckoutButtonText: doppeHideableValueCreateHidden(previewCheckoutButtonText),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(requestOption.id),
                        ...requestOption as PartiallyOptional<DoppeActionTypeRequests.RequestSettings, 'requestsInstructions' | 'showNotesInput' | 'ribbon' | 'previewCheckoutButtonText' | 'enabled'>
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...restSettings,
            checkoutSettings: getDefaultDoppeCheckoutSettingsForAction(checkoutSettings),
        }
    },
    prepareForViewer: (actionSettings, options) => {
        prepareCheckoutSettingsForViewer(actionSettings);
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: true,
    getSupportedAutomationTrigger: () => DoppeAutomationTriggers.PaidRequestPaymentDone,
    isFormAction: false,
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_monetization_actions)
    },
    isListItemLinkOpener: false,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.images ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.images?.[0] ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.listDataItems.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.images ? asArray(item.images)[0] : null,
                itemId: item.id,
                ribbon: doppeHideableValueCreateHidden(''),
                url: null,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.listDataItems) {
                if (item.images) {
                    const images = asArray(item.images);
                    item.images = images.map(image => {
                        return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    });
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});
